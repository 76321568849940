import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/training/Hero";
  import Content from "../sections/training/Content";
  import CTA from "../sections/training/CTA";

const TrainingPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          footerStyle: 'style4'
        }}
      >
        <Hero />
          <Content />
          <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default TrainingPage;
